@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@font-face {
    font-family: "ESRebondGrotesque Medium";
    src: url(./fonts/ESRebondGrotesque-Medium.woff2)format('woff2');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    /* General */

    html {
        @apply bg-sf-beige text-sf-black font-primary font-normal not-italic h-full w-full antialiased select-none;
    }

    /* Text */

    h1 {
        @apply text-2xl lg:text-3xl col-span-full font-es-rebond-grotesque font-bold
    }

    h2 {
        @apply text-xl font-es-rebond-grotesque
    }

    h3 {
        @apply text-sm font-bold flex
    }

    p {
        @apply text-sm
    }

    small {
        @apply text-xs
    }

    .secondary {
        @apply text-sf-gray-dark truncate
    }

    .income {
        @apply font-bold text-sf-green-dark
    }

    .expenditure {
        @apply font-bold text-sf-red-dark
    }

    /* Elements */

    main {
        @apply space-y-3 p-4 lg:space-y-8 lg:px-12 xl:grid xl:grid-cols-2 xl:gap-8 xl:space-y-0
    }

    article {
        @apply flex flex-col p-3 space-y-3 rounded-2xl bg-sf-white col-auto
    }

    .fallback-page {
        @apply flex flex-col w-screen h-screen items-center lg:justify-center lg:pb-32
    }

    .field {
        @apply flex border-0 w-full p-3 rounded-lg text-sm bg-sf-white focus:ring-sf-violet-dark placeholder:text-sf-black
    }

    .button-primary {
        @apply cursor-pointer w-fit my-2 rounded-full bg-sf-violet-dark disabled:bg-sf-gray-medium disabled:cursor-default disabled:text-sf-gray-dark px-8 py-2 text-base text-sf-white
    }

    .button-secondary {
        @apply cursor-pointer w-fit rounded-lg disabled:text-sf-gray-dark px-2 p-1 text-sm font-bold text-sf-violet-dark disabled:cursor-default
    }

    .button-secondary-red {
        @apply button-secondary text-sf-red-dark
    }

    .button-aux {
        @apply cursor-pointer flex text-sm place-items-center border-b border-sf-black h-fit
    }
}