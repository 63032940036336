@import url('index.css');

:root {
    --amplify-components-authenticator-modal-background-color: '#F0E6D8'
}

[data-amplify-authenticator] [data-amplify-router] {
    @apply rounded-md border-0 bg-sf-white font-[Inter] shadow-none
}

.amplify-tabs__panel {
    @apply p-0
}

.amplify-tabs__list--top {
    @apply border-0
}

.amplify-tabs__item {
    @apply border-0 mt-0 ring-0 rounded-md bg-sf-gray-extra-light font-normal text-sm hover:text-base text-sf-black hover:text-sf-black
}

.amplify-tabs__item--active {
    @apply bg-sf-white font-normal text-sf-black hover:text-sf-black underline underline-offset-4 cursor-default text-base
}

.federated-sign-in-button {
    @apply mb-4 font-normal
}

.amplify-divider {
    @apply border-sf-black opacity-100
}

.amplify-divider--label::after {
    @apply text-sf-black
}

.amplify-input {
    @apply field ring-sf-violet-dark ring-1
}

.amplify-button.amplify-field-group__control:not(.amplify-button--primary):not(.amplify-field__show-password):not(.federated-sign-in-button) {
    @apply button-secondary border-0
}

.amplify-field__show-password {
    @apply rounded-lg p-3.5 ring-sf-violet-dark ring-1 border-0
}

.amplify-label {
    @apply text-sf-black text-sm
}

.amplify-button {
    @apply self-center w-fit bg-sf-white
}

.amplify-button:focus {
    @apply ring-sf-violet-dark ring-1 border-0
}

.federated-sign-in-button {
    @apply border-sf-black hover:border-sf-black
}

.amplify-button--primary {
    @apply button-primary
}

.amplify-button--small {
    @apply button-secondary
}

[data-amplify-authenticator] [data-amplify-footer] {
    @apply pb-0
}